// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.cpbulba-events {
    background: $background-color2;
    padding: 3rem 2rem;
    @include media("tablet", max) {
        padding: 2rem 1rem;
    }
    .wrapper {
        max-width: 1280px;
        margin: auto;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .heading {
            margin: 0;
            a {
                color: $gradient-color1;
            }
        }
        .button {
            font-size: .85rem;
            display: inline-block;
            color: $gradient-color1;
            border: 1px solid $gradient-color1;
            padding: .5rem;
            border-radius: .25rem;
            &:hover {
                background: rgba($gradient-color1, 0.1);
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @include media("tablet-wide", min) {
            display: flex;
        }
        li {
            box-sizing: border-box;
            display: flex;
            width: 100%;
            @include media("tablet-wide", min) {
                flex-basis: calc(100%/3);
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
            @include media("tablet-wide", max) {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
            a {
                overflow: hidden;
                border-radius: 1rem;
                background: $background-color;
                box-shadow: 0 1rem 1rem rgba(#000, 0.15);
                width: 100%;
                padding: 0;
                display: flex;
                &:hover {
                    background: $acqua;
                    box-shadow: 0 0 0 0 rgba(#000, 0);
                    .date {
                        background: $acqua;
                        color: $gradient-color1;
                        &>*:nth-child(3) {
                            color: $gradient-color3;
                        }
                    }
                    .content {
                        span {
                            color: $gradient-color1;
                        }
                        .heading {
                            color: $gradient-color1;
                        }
                        img {
                            opacity: .75;
                        }
                    }
                }
            }
            .date {
                color: $acqua;
                background: $gradient-color1;
                border-right: 2px solid $gradient-color1;
                font-family: $heading-font-family;
                font-weight: $heading-font-weight;
                padding: 1.5em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                transition: all 150ms ease-in-out;
                @include media("tablet-wide", max) {
                    padding: 1em;
                }
                &>* {
                    transition: all 150ms ease-in-out;
                    line-height: 1.2;
                    &:nth-child(2) {
                        font-size: 2em;
                    }
                    &:nth-child(3) {
                        font-size: .85em;
                        color: #fff;
                        white-space: nowrap;
                    }
                }
            }
            .content {
                flex: 1;
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                @include media("tablet-wide", max) {
                    padding: 1em;
                }
                span {
                    width: 100%;
                    color: $text-color;
                    margin-bottom: 1em;
                    line-height: 1;
                }
                .heading {
                    font-size: 1.25em;
                    margin-bottom: .25em;
                }
                img {
                    max-height: 3em;
                    border-radius: .25rem;
                    mix-blend-mode: multiply;
                    background: none !important;
                }
            }
        }
    }
}